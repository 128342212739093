import * as React from 'react'
import { type ReactNode, useRef, useState } from 'react'

import { cn } from '~/utils/misc.tsx'

export type InputVariant = 'outlined' | 'outlined-label' | 'filled'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  leftIcon?: React.ReactNode
  variant?: InputVariant
}

export interface InputLabelPlaceholderProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  rightIcon?: ReactNode
  leftIcon?: ReactNode
}

const InputLabelPlaceholder = React.forwardRef<
  HTMLInputElement,
  InputLabelPlaceholderProps
>(
  (
    { className, type, placeholder, id, value, rightIcon, leftIcon, ...props },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [isFocused, setIsFocused] = useState(false)

    const labelShowPlaceholderStyles = `
        peer-placeholder-shown:top-2.5 peer-placeholder-shown:translate-y-0  peer-placeholder-shown:scale-100
        peer-placeholder-shown:text-foreground-muted peer-placeholder-shown:font-normal     `
    const labelShowLabelStyles = `
        left-5 top-0 -translate-y-2 scale-75 duration-200 origin-top-left text-secondary font-semibold peer-focus:font-semibold
        peer-focus:left-5 peer-focus:top-0 peer-focus:-translate-y-2 peer-focus:scale-75 peer-focus:text-secondary
`

    const handleFocus = (event: any) => {
      props.onFocus && props.onFocus(event)
      setIsFocused(true)
    }
    const handleBlur = (event: any) => {
      props.onBlur && props.onBlur(event)
      setIsFocused(false)
    }
    return (
      <fieldset
        className={cn(
          `relative h-[44px] w-full cursor-text rounded-lg border border-input px-4 hover:border-secondary focus:border-2 ${
            isFocused && 'border-2 border-secondary'
          }`,
          className,
        )}
      >
        <input
          ref={el => {
            // Assign the input ref to the provided ref
            if (ref) {
              if (typeof ref === 'function') {
                ref(el)
              } else if (ref.hasOwnProperty('current')) {
                // @ts-ignore
                ref.current = el
              }
            }
            inputRef.current = el
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={type}
          id={id ?? placeholder}
          aria-describedby="filled_success_help"
          className={cn(
            inputStandardClassName,
            `peer -ml-4 block h-full w-[calc(100%+32px)] appearance-none !rounded-lg
          border-none
           bg-white py-3
           pl-5  text-foreground focus:ring-0
        `,
            leftIcon && 'pl-10',
          )}
          placeholder=" "
          name={`input-${placeholder}`}
          value={value}
          {...props}
        />
        <legend
          className={`invisible h-[5px] px-1 text-xs  peer-placeholder-shown:hidden peer-focus:block`}
        >
          {placeholder}
        </legend>
        {leftIcon && (
          <div
            className="absolute left-4 top-0 flex h-full cursor-text items-center text-foreground-muted"
            onClick={() => inputRef.current!.focus()}
          >
            {leftIcon}
          </div>
        )}
        <label
          htmlFor={id ?? placeholder}
          className={cn(
            'absolute cursor-text font-normal',
            labelShowLabelStyles,
            labelShowPlaceholderStyles,
            leftIcon && 'peer-placeholder-shown:pl-5 peer-focus:pl-0',
          )}
        >
          {placeholder}
        </label>
        {rightIcon && <div className="absolute right-4 top-4">{rightIcon}</div>}
      </fieldset>
    )
  },
)

InputLabelPlaceholder.displayName = 'InputLabelPlaceholder'

const inputStandardClassName =
  'flex h-11 w-full rounded-md border border-input px-3 py-2 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-muted focus-visible:outline-none   focus-visible:border-2 focus-visible:border-secondary hover:border-secondary disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid'

export interface InputStandardProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  leftIcon?: React.ReactNode
  variant: InputVariant
}

const InputStandard = React.forwardRef<HTMLInputElement, InputStandardProps>(
  ({ className, type, leftIcon, variant, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const inputClassName =
      variant === 'filled'
        ? `${inputStandardClassName} bg-background`
        : inputStandardClassName
    return leftIcon ? (
      <div className={cn('relative w-full', className)}>
        <div
          className="absolute left-4 flex h-11 cursor-text items-center text-foreground-muted"
          onClick={() => inputRef.current!.focus()}
        >
          {leftIcon}
        </div>
        <input
          type={type}
          className={cn(
            inputClassName,
            leftIcon ? 'pl-10' : 'pl-8 ',
            className,
          )}
          ref={el => {
            // Assign the input ref to the provided ref
            if (ref) {
              if (typeof ref === 'function') {
                ref(el)
              } else if (ref.hasOwnProperty('current')) {
                // @ts-ignore
                ref.current = el
              }
            }
            inputRef.current = el
          }}
          {...props}
        />
      </div>
    ) : (
      <input
        type={type}
        className={cn(inputClassName, className)}
        ref={ref}
        {...props}
      />
    )
  },
)

InputStandard.displayName = 'InputStandard'

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ variant = 'outlined', ...props }, ref) => {
    return variant === 'outlined' ? (
      <InputStandard variant={variant} {...props} ref={ref} />
    ) : variant === 'outlined-label' ? (
      <InputLabelPlaceholder {...props} ref={ref} />
    ) : variant === 'filled' ? (
      <InputStandard variant={variant} {...props} ref={ref} />
    ) : null
  },
)

Input.displayName = 'Input'

export { Input }
